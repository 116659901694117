const SIZE = '60px'
const FONT_SIZE = '40px'
const TX = '-65px'
const TY = '0px'
const BLUE = '#4748ec'
const GREEN = '#12b886'
const ORANGE = '#C26648'
const RED = '#fa5252'
const OPACITY = 0.1

const numStyle = color => `
position:absolute;
z-index:-1;
transform:translateX(${TX}) translateY(${TY}) scale(2);
color:${color};
border:3px solid ${color};
opacity:${OPACITY};
width:${SIZE};
height:${SIZE};
font-size:${FONT_SIZE};
font-weight:normal;
border-radius:50%;
text-align:center;
line-height:${SIZE};
`

export const ONE = `<span style="${numStyle(BLUE)}">1</span>`
export const TWO = `<span style="${numStyle(GREEN)}">2</span>`
export const THREE = `<span style="${numStyle(ORANGE)}">3</span>`
export const FOUR = `<span style="${numStyle(GREEN)}">4</span>`
export const FIVE = `<span style="${numStyle(RED)}">5</span>`
export const SIX = `<span style="${numStyle(BLUE)}">6</span>`
export const SEVEN = `<span style="${numStyle(ORANGE)}">7</span>`
export const EIGHT = `<span style="${numStyle(RED)}">8</span>`
export const NINE = `<span style="${numStyle(BLUE)}">9</span>`
